.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
  overflow: hidden;
  background-color: white;
  &__self {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__loader {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    border: 5px solid $color-gray-4;
    margin: 0 auto;
    border-top-color: $color-primary-dark;
    animation: rotate-loader 1.5s infinite;
  }
  &__text {
    font-weight: 300;
    font-size: 1.4rem;
    text-align: center;
    margin-top: 2rem;
  }
}

@keyframes rotate-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
