.section-contact {
  background-color: rgba($color-primary-dark, 0.65);
  box-shadow: 0px 2px 4px rgba($color-black, 0.1);
  padding: 2rem;
  .contact-card {
    background-color: #fcfcfc;
    border-radius: 1rem;
    transition: all 0.5s ease-in-out;
    margin-bottom: 2rem;
    height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:hover {
      background-color: $color-white;
      box-shadow: 0px 2px 4px rgba($color-black, 0.25);
    }
    &__heading {
      text-align: center;
      text-transform: capitalize;
      margin-bottom: 1.5rem;
      font-weight: 700;
      color: $color-primary-dark;
      font-size: 1.8rem;
    }
    &__subheading {
      text-align: center;
      text-transform: uppercase;
      color: $color-4;
      font-weight: 700;
      font-size: 2.4rem;
      transition: all 0.5s;
      a {
        color: $color-4;
        text-decoration: none;
        cursor: pointer;
      }
    }
    &:hover {
      .contact-card__subheading {
        font-size: 2.8rem;
      }
    }
  }
  .custom-select {
    border-radius: 5rem;
    font-size: 1.4rem;
  }
}
