.police-stations {
  .custom-select {
    border-radius: 5rem;
    font-size: 1.4rem;
  }
  .card-link:link,
  .card-link:visited {
    transition: all 0.5s;
    color: $color-black;
  }
  i.fa {
    width: 1.5rem;
  }
  .card-link:hover,
  .card-link:active {
    color: $color-primary;
  }
  &__image-container {
    height: 25rem;
    background-color: $color-gray-4;
    box-shadow: 0px 2px 4px rgba($color-black, 0.25);
  }
  &__image {
    display: block;
    height: 100%;
    width: 100%;
    margin: 0 auto;
  }
  .card {
    transition: all 0.5s;
    box-shadow: 0px 2px 4px rgba($color-black, 0.25);
  }
  .card:hover {
    transform: translateY(-2px) scale(1.02);
  }
  .card-img {
    height: 17rem;
    width: 100%;
  }
  .card-text {
    display: flex;
    align-items: center;
    i {
      flex: 1;
    }
    span {
      flex: 25;
    }
  }
}

@media only screen and (max-width: 992px) {
  .police-stations {
    .card-img {
      height: auto;
      width: 100%;
    }
  }
}
