.data-carousel__item {
  padding: 2rem;
  box-shadow: 0px 2px 4px rgba($color-black, 0.25);
  margin: 1px 2rem;
  background-color: $color-white;
  height: 57rem;
  p {
    margin-top: 1rem;
    text-align: justify;
  }
}
.background-strip-container {
  position: relative;
}
.background-strip {
  width: 100%;
  height: 20rem;
  background-color: $color-6;
  position: absolute;
  top: 45%;
}
.react-multiple-carousel__arrow::before {
  color: $color-white;
  font-weight: bolder;
}
.react-multiple-carousel__arrow {
  background-color: $color-primary-dark;
  transition: all 0.5s;
}
.react-multiple-carousel__arrow:hover {
  background-color: rgba($color-primary-dark, 0.75);
  transform: translateY(-2px);
}
.react-multiple-carousel__arrow--left,
.react-multiple-carousel__arrow--right {
  top: 10rem;
  box-shadow: 0px 2px 4px rgba($color-black, 0.25);
}
.data-carousel__button {
  font-size: 1.8rem;
  padding: 1rem 5rem;
  text-transform: uppercase;
  font-weight: bold;
}

@media only screen and (max-width: 992px) {
  .data-carousel__item {
    height: 63rem;
  }
}
