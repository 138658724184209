:root {
  --initial-value: 0%;
  --final-value: -100%;
  --timing: 10s;
}
.section-notifications {
  box-shadow: 0px 2px 4px rgba($color-black, 0.1);
  padding: 2rem;
  background-color: $color-white;
  .notifications {
    border-radius: 1rem;
    overflow: hidden;
    box-shadow: 0px 2px 4px rgba($color-black, 0.25);
    cursor: pointer;
    background-color: $color-white;
  }
  .notifications-header {
    background-color: $color-4;
    color: $color-gray-5;
    padding: 1.5rem;
    h4 {
      margin: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .notifications-body {
    overflow: hidden;
    height: 47rem;
  }
  .notifications-container {
    margin: 2rem;
    height: 43rem;
    overflow: hidden;
  }
  .notifications-list {
    list-style: none;
    position: relative;
    margin: 0 2px;
  }
  .notifications-item {
    padding: 1rem;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 2rem;
    border-left: 1rem solid $color-primary-dark;
  }
  .notifications-link:link,
  .notifications-link:visited {
    color: $color-black;
    i {
      color: $color-primary;
      margin-right: 0.5rem;
    }
  }
  .notifications-link:hover,
  .notifications-link:active {
    color: $color-primary;
  }
  .start-animation {
    animation: spin-news var(--timing) linear infinite both;
  }
  .start-animation:hover,
  .stop-animation {
    animation-play-state: paused;
  }
  .col-md-3,
  .col-md-4 {
    margin-bottom: 2rem;
  }
  .card2 {
    border-radius: 1rem;
    border: 1px solid $color-gray-4;
    display: block;
    text-decoration: none;
    transition: transform 0.5s ease-in-out;
    padding: 1rem;
    color: $color-primary-dark;
    font-weight: bold;
    font-size: 1.4rem;
    background-color: #fcfcfc;
    img {
      display: block;
      margin: 0 auto;
      width: 50%;
      margin-bottom: 1rem;
    }
    hr {
      width: 80%;
      height: 0.2rem;
      display: block;
      margin: 0 auto;
      background-color: $color-primary-dark;
      border-radius: 1rem;
    }
    p {
      text-align: center;
      margin-top: 1rem;
    }
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0px 4px 8px rgba($color-black, 0.25);
      border: none;
      background-color: $color-white;
    }
  }
}

@keyframes spin-news {
  0% {
    top: var(--initial-value);
  }
  100% {
    top: var(--final-value);
  }
}
