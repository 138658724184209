.PrimaryNav {
  background-color: $color-primary-dark;
  padding: 0 2rem;
  .dropdown-menu {
    padding: 0;
  }
  a:link,
  a:visited {
    text-transform: uppercase;
    color: $color-gray-5 !important;
    background-color: $color-primary-dark;
    font-size: 1.6rem;
    font-weight: 300;
    border: 1px solid $color-primary-dark;
    padding: 0.6rem 1rem !important;
  }
  a.navbar-brand:link,
  a.navbar-brand:visited,
  a.navbar-brand:hover,
  a.navbar-brand:active {
    background-color: $color-primary-dark;
    padding: 0;
  }
  a:hover,
  a:active,
  a.active {
    color: $color-primary-dark !important;
    background-color: $color-gray-5;
  }
  a.external.active,
  a.external:active {
    color: $color-gray-5 !important;
    background-color: $color-primary-dark !important;
  }
  .navbar-toggler {
    background-color: $color-gray-5;
  }
}

@media only screen and (max-width: 992px) {
  .PrimaryNav {
    padding: 1rem;
    #basic-navbar-nav {
      position: absolute;
      left: 0;
      right: 0;
      top: 4.5rem;
      z-index: 1000;
    }
    .dropdown-menu {
      border: 1px solid $color-secondary;
      .dropdown-item {
        padding-left: 4rem !important;
      }
    }
  }
}
