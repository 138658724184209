.section-subfooter {
  box-shadow: 0px 2px 4px rgba($color-black, 0.25);
  padding: 4rem 0;
  border-top: 1px solid $color-gray-4;
  background-color: $color-primary;
  &__self {
  }
  &__item {
  }
  &__image {
    display: block;
    margin: 0 auto;
    width: 65%;
    background-color: $color-white;
  }
}
