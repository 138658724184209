.section-list-swiper {
  box-shadow: 0px 2px 4px rgba($color-black, 0.1);
  padding: 2rem;
  &__card {
    box-shadow: 0px 2px 4px rgba($color-black, 0.1);
    padding: 2rem;
  }
  &__image {
    display: block;
    width: 100%;
  }
  &__heading {
    font-weight: 700;
    margin: 1rem 0;
  }
  &__text {
    font-size: 1.4rem;
    margin-bottom: 0;
  }
  &__link:link,
  &__link:visited,
  &__link:hover,
  &__link:active {
    color: $color-primary;
    text-decoration: none;
  }
  &__list-container {
    height: 100%;
  }
  &__list {
    box-shadow: 0px 2px 4px rgba($color-black, 0.1);
    padding: 2rem;
  }
  &__item {
    box-shadow: 0px 2px 4px rgba($color-black, 0.1);
    padding: 0.8rem;
    margin: 0.8rem 0;
    cursor: pointer;
  }
  &__item-removed {
    animation: remove-element-from-list 200ms
      cubic-bezier(0.39, 0.575, 0.565, 1) forwards;
    transform-origin: top;
  }

  &__item-title {
    font-weight: 700;
  }
  &__item-text {
    font-size: 1.4rem;
    margin-bottom: 0;
  }
}

@keyframes remove-element-from-list {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@media only screen and (max-width: 992px) {
}
