.section-services {
  box-shadow: 0px 2px 4px rgba($color-black, 0.1);
  padding: 2rem 4rem;
  .services {
    & .main-card {
      overflow: hidden;
      border-radius: 1rem;
      box-shadow: 0px 2px 4px rgba($color-black, 0.1);
    }
    &__title {
      background-color: rgba($color-5, 0.1);
      font-size: 1.6rem;
      padding: 1rem 2rem;
      margin-bottom: 0;
      text-transform: uppercase;
      transition: all 0.5s;
      position: relative;
      border-bottom: 2px solid transparent;
      color: $color-primary-dark;
    }
    &__title:hover:not(:last-child),
    &__title.selected:not(:last-child) {
      border-bottom: 2px solid rgba($color-primary-dark, 0.5);
    }
    &__title:hover,
    &__title.selected {
      transform: translateY(-2px);
      background-color: rgba($color-5, 0.28);
      box-shadow: 0px 2px 4px rgba($color-black, 0.1);
      font-weight: bold;
    }
    &__title .fa {
      position: absolute;
      right: 2rem;
      color: $color-primary-dark;
    }
    &__details {
      & .card {
        background-color: $color-white;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 2rem 0;
        border-radius: 1rem;
        transition: all 0.5s;
        border: none;
        margin-bottom: 1rem;
        box-shadow: 0px 2px 4px rgba($color-black, 0.1);
      }
      & .card__image {
        width: 25%;
      }
      & .card__title {
        margin-top: 1rem;
        text-transform: uppercase;
        margin-bottom: 1rem;
        color: $color-primary-dark;
      }
      & .card__tips {
        display: flex;
      }
      & .card__tip {
        background-color: $color-white;
        padding: 0.25rem 0.8rem;
        font-size: 1.2rem;
        margin: 0 0.2rem;
        border-radius: 0.5rem;
        box-shadow: rgba($color-black, 0.25);
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .section-services {
    .services {
      & .main-card {
        margin-bottom: 1rem;
      }
    }
  }
}
