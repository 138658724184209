:root {
  --timer: 10s;
  --start-value: 0%;
  --end-value: -100%;
}
.section-news {
  box-shadow: 0px 2px 4px rgba($color-black, 0.1);

  &__title {
    background: linear-gradient(to right, $color-primary-dark, $color-primary);
    color: $color-secondary;
    padding: 1rem;
    font-size: 1.8rem;
    text-align: center;
    font-weight: 700;
    margin-bottom: 0;
  }

  .ticker-wrap {
    background-color: white;
    padding: 1.6rem;
    position: relative;
    overflow: hidden;
    height: 100%;
  }

  .ticker-move {
    position: absolute;
    width: max-content;
    height: 100%;
    top: 0;
    display: flex;
    align-items: center;
  }

  .ticker-link:link,
  .ticker-link:visited,
  .ticker-link:hover,
  .ticker-link:active {
    color: $color-black;
    text-decoration: none;
    display: inline-block;
    margin-right: 1.6rem;
  }
  .ticker-link::before {
    content: "\00bb";
    display: inline;
    margin-right: 1rem;
    font-size: 1.8rem;
  }
  .start-ticker {
    animation: newsTravel var(--timer) linear forwards infinite;
  }
  .start-ticker:hover,
  .stop-ticker {
    animation-play-state: paused;
  }
}

@keyframes newsTravel {
  0% {
    transform: translateX(var(--start-value));
  }
  100% {
    transform: translateX(var(--end-value));
  }
}
