*,
*::before,
*::after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}

html {
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  font-size: 1.6rem;
  line-height: 1.8;
}

.twitter-timeline {
  width: 100% !important;
}

.fb-page.fb_iframe_widget.fb_iframe_widget_fluid {
  span {
    width: 100% !important;
    iframe {
      width: 100% !important;
    }
  }
}
