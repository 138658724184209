// COLOR PALLETE 3
$color-primary: #3f4db8;
$color-primary-dark: #0a2463;
$color-secondary: #ebebeb;
$color-3: #59c3c3;
$color-4: #f45b69;
$color-5: #5995ed;
$color-6: #cee1f6;

$color-gray-1: #333;
$color-gray-2: #777;
$color-gray-3: #999;
$color-gray-4: #ccc;
$color-gray-5: #fcfcfc;

$color-black: #000;
$color-white: #fff;
