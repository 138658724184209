.Carousel {
  .carousel-caption {
    background-color: rgba($color-black, 0.7);
    padding: 1rem 1rem;
    bottom: 0;
    left: 0;
    right: 0;
  }
  &__image {
    width: 100%;
  }
  .carousel-indicators li {
    background-color: rgba($color-black, 0.7);
  }
}

@media only screen and (max-width: 992px) {
  .Carousel {
    &__caption {
      font-size: 1.2rem;
      line-height: 1.2;
      margin-bottom: 0;
    }
    .Carousel .carousel-caption {
      padding: 0.5rem 1rem;
    }
  }
}
