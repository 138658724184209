.senior-officers {
  &__container {
  }
  .card,
  .card img {
    transition: all 0.5s;
    box-shadow: 0px 2px 4px rgba($color-black, 0.25);
  }
  .card:hover {
    transform: scale(1.02);
  }
  i.fa {
    width: 1.5rem;
  }
  &__link:link,
  &__link:visited {
    color: $color-black;
    transition: all 0.5s;
  }
  &__link:hover,
  &__link:active {
    color: $color-primary;
  }
  &__image-container {
    background-color: $color-5;
    height: 20rem;
    position: relative;
  }
  &__image {
    display: block;
    height: 15rem;
    width: 15rem;
    border-radius: 50%;
    border: 5px solid $color-white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $color-white;
  }
  &__image-mask {
    clip-path: polygon(0 0, 0% 100%, 100% 0, 0 0);
    background-color: $color-primary-dark;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
  }
  .custom-select {
    border-radius: 5rem;
    font-size: 1.4rem;
  }
  tr {
    transition: all 0.5s;
    td {
      cursor: pointer;
    }
  }
  tr:hover {
    transform: scaleY(1.02);
    box-shadow: 0px 2px 4px rgba($color-black, 0.25);
  }
}
