.footer {
  padding: 4rem;
  background-color: rgba($color-black, 0.85);
  &__heading {
    color: $color-secondary;
    text-transform: uppercase;
    margin: 1rem;
  }
  .fb-page.fb_iframe_widget iframe {
    border-radius: 0.5rem;
  }
  &__list {
    list-style-type: none;
  }
  &__item {
    display: block;
  }
  &__link:link,
  &__link:visited {
    color: $color-white;
    font-size: 1.4rem;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 0.5s ease-in-out;
    display: inline-flex;
    align-items: center;
    i {
      flex: 1;
    }
  }
  &__link:hover,
  &__link:active {
    color: $color-3;
    transform: translateY(-2px);
  }
  &__item i {
    margin-right: 1rem;
  }
  .copyright {
    margin-top: 2rem;
    &__text {
      color: $color-secondary;
      text-align: center;
      font-size: 1.2rem;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    &__text span {
      font-weight: 700;
    }
  }
  .twitter-timeline,
  .timeline-Widget {
    max-width: 100%;
  }
}
