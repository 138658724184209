.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  padding: 1.6rem;
  &__left {
    display: flex;
    align-items: center;
  }
  &__image2 {
    height: 11rem;
    width: auto;
  }
  &__title {
    margin-left: 1.6rem;
    h2 {
      text-transform: uppercase;
    }
  }
}

@media only screen and (max-width: 992px) {
  .Header {
    width: 100%;
    &__title h2 {
      font-size: 1.2rem;
    }
    &__image1,
    &__image2 {
      height: 5rem;
      width: auto;
    }
    &__right {
      display: none;
    }
  }
}
