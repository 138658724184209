.page-not-found {
  width: 100%;
  height: 100vh;
  position: relative;
  &__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__image {
    height: 60%;
    display: block;
    margin: 0 auto;
  }
  &__heading {
    font-weight: 700;
    font-size: 5rem;
    text-align: center;
    margin-top: 2rem;
  }
  &__button {
    margin: 2rem auto;
    display: block;
    width: 20%;
  }
}
