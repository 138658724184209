.section-info {
  background-color: rgba($color-3, 0.15);
  box-shadow: 0px 2px 4px rgba($color-black, 0.1);
  padding-bottom: 2rem;

  &__image {
    display: block;
    width: 75%;
    box-shadow: 0px 2px 4px rgba($color-black, 0.1);
    margin: 0 auto;
    border-radius: 1rem;
  }
  &__paragraph {
    position: relative;
    text-align: justify;
  }
  &__paragraph,
  &__designation {
    font-size: 1.8rem;
    line-height: 2;
  }
  &__paragraph:first-of-type::before {
    content: "\0022";
    display: inline;
    color: $color-4;
    font-size: 5rem;
    font-weight: bold;
    position: absolute;
    top: -3rem;
    left: -3rem;
    font-family: serif;
  }
  &__paragraph:nth-of-type(2)::after {
    content: "\0022";
    display: inline;
    color: $color-4;
    font-size: 5rem;
    font-weight: bold;
    font-family: serif;
    position: absolute;
    bottom: -3.5rem;
  }
  &__from {
    font-weight: 700;
    text-transform: uppercase;
  }
  &__from span {
    font-weight: 400;
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 992px) {
  .section-info {
    padding: 0 2rem;
    &__paragraph:first-of-type {
      margin-top: 2rem;
    }
  }
}
