.section__title {
  padding: 4rem;
  &text {
    font-size: 2.4rem;
    font-weight: 700;
    color: $color-primary-dark;
    text-align: center;
    margin-bottom: 1rem;
  }
  &hr {
    width: 5rem;
    height: 0.5rem;
    background-color: $color-primary-dark;
    border-radius: 1rem;
    margin: 0 auto;
  }
}
