.subheader {
  background-color: rgba($color-gray-4, 0.5);
  padding: 0.5rem;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  &__left {
  }
  &__right {
    margin-left: auto;
  }
  &__text {
    margin: 0 1rem;
  }
  &__button {
    border: 1px solid $color-gray-3;
    padding: 0 0.5rem;
    margin-right: 0.5rem;
    border-radius: 0.5rem;
    font-weight: 700;
    color: $color-gray-5;
    background-color: $color-gray-1;
    transition: all 0.5s;
    width: 3rem;
  }
  &__button:hover {
    color: $color-gray-1;
    background-color: $color-gray-5;
  }
}

@media only screen and (max-width: 992px) {
  .subheader {
    flex-direction: column;
    align-items: center;
    &__left {
    }
    &__right {
      margin-left: 0;
      margin-top: 0.5rem;
    }
    &__text {
    }
  }
}
