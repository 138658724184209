.photo-gallery {
  position: relative;
  &__item {
    width: 100%;
    height: 100%;
    position: relative;
    border: 5px solid $color-gray-4;
    border-radius: 0.5rem;
    transition: all 0.5s;
    box-shadow: 0px 2px 4px rgba($color-black, 0.25);
    cursor: pointer;
  }
  &__item:hover {
    transform: translateY(-5px) scale(1.02);
  }
  &__image {
    display: block;
    width: 100%;
    height: 100%;
  }
  &__title {
    font-size: 1.4rem;
    font-weight: bold;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color-black, 0.5);
    color: $color-white;
    margin: 0;
    padding: 1rem;
  }

  &__modal {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($color-black, 0.75);
    .controls__left {
      position: absolute;
      top: 50%;
      left: 2rem;
      transform: translateY(-50%);
      color: $color-white;
      background-color: rgba($color-black, 0.75);
      padding: 1rem 2rem;
    }
    .controls__right {
      position: absolute;
      top: 50%;
      right: 2rem;
      transform: translateY(-50%);
      color: $color-white;
      background-color: rgba($color-black, 0.75);
      padding: 1rem 2rem;
    }
  }
  &-inner-container {
    width: 60%;
    height: 60%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .controls__close {
      position: absolute;
      top: 0;
      right: 0;
      color: $color-white;
    }
    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .gallery-item-grid {
    width: 100%;
    margin: 0 auto 2rem auto;
    columns: 3;
    column-gap: 2rem;
    .gallery-item {
      width: fit-content;
      margin: 0 0 2rem;
      padding: 1rem;
      background-color: $color-white;
      box-shadow: 0px 2px 4px rgba($color-black, 0.25);
      overflow: hidden;
      break-inside: avoid;
    }
    .gallery-item-image {
      max-width: 100%;
      height: auto;
    }
  }

  .data-container {
    padding: 2rem;
    box-shadow: 0px 2px 4px rgba($color-black, 0.25);
    .card-header {
      background-color: $color-3;
      color: $color-white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }
    p {
      text-align: justify;
      a {
        color: $color-black;
        text-decoration: none;
      }
    }
    ul {
      list-style: none;
      li {
        display: flex;
        align-items: flex-start;
        i {
          flex: 2;
          font-size: 1.2rem;
          margin-top: 0.8rem;
        }
        p,
        h2 {
          flex: 100;
          padding-left: 0.5rem;
          a {
            color: $color-black;
            text-decoration: none;
          }
        }
      }
    }
    .image-container {
      box-shadow: 0px 2px 4px rgba($color-black, 0.25);
      padding: 2rem;
      height: 100%;
    }
  }

  .data-container-2 {
    padding: 2rem;
    box-shadow: 0px 2px 4px rgba($color-black, 0.25);
    .card-header {
      background-color: $color-3;
      color: $color-white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }
    p {
      text-align: justify;
      a {
        color: $color-black;
        text-decoration: none;
      }
    }
    ul {
      list-style: none;
      li {
        background-color: #5995ed;
        color: #ffffff;
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 1rem;
        transition: all 0.5s;
        text-transform: uppercase;
        cursor: pointer;
        p {
          margin-bottom: 0;
        }
        a {
          margin-left: 1rem;
          margin-right: 1rem;
          color: $color-white;
          i {
            font-size: 3rem;
          }
        }
      }
      li:not(:last-of-type) {
        margin-bottom: 1rem;
      }
      li:hover {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
        transform: translateY(-2px) scale(1.02);
      }
    }
  }
}

.photo-gallery {
  @media only screen and (max-width: 992px) {
    &__modal {
      width: 100vw;
      height: 100vh;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba($color-black, 0.75);
      .controls__left {
        position: absolute;
        top: 50%;
        left: 2rem;
        transform: translateY(-50%);
        color: $color-white;
        background-color: rgba($color-black, 0.75);
        padding: 0.5rem 1rem;
        font-size: 1rem;
      }
      .controls__right {
        position: absolute;
        top: 50%;
        right: 2rem;
        transform: translateY(-50%);
        color: $color-white;
        background-color: rgba($color-black, 0.75);
        padding: 0.5rem 1rem;
        font-size: 1rem;
      }
    }
    .gallery-item-grid {
      columns: 3;
    }
    &-inner-container {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .controls__close {
        position: absolute;
        top: 2rem;
        right: 2rem;
        color: $color-white;
      }
      img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .photo-gallery {
    .gallery-item-grid {
      columns: 2;
    }
  }
}

@media only screen and (max-width: 480px) {
  .photo-gallery {
    .gallery-item-grid {
      columns: 1;
    }
  }
}
